<template>
    <div>
      <!-- Totalizer Table -->
      <b-table striped bordered hover :items="items" :fields="fields">
        <!-- Custom Header Slot -->
        <template v-slot:thead-top="data">
          <tr>
            <th colspan="3" class="text-center text-primary">{{ description }}</th>
            <th colspan="2" rowspan="2" class="text-center">Promotores</th>
            <th colspan="2" rowspan="2" class="text-center">Detratores</th>
            <th colspan="2" rowspan="2" class="text-center">Neutros</th>
            <th rowspan="2" class="text-center">NPS</th>
          </tr>
          <tr>
          </tr>
        </template>
      </b-table>
    </div>
  </template>
  
  <script>
  export default {
    name: 'BackofficeTotalizer',
    props: {
        description: {
          type: String,
          default: ''
        },
        totalizer: Object
    },
    data() {
      return {
        items: [
          
        ],
        // Fields definition for the table
        fields: [
          { key: 'QTDEnviadas', label: 'Qtde Enviadas', class: 'text-center' },
          { key: 'QTDRespondidas', label: 'Qtde Respondidas', class: 'text-center' },
          { key: 'percRespostas', label: '% de Respostas', class: 'text-center', formatter: (value) => `${value}%` },
          { key: 'PromotoresQtd', label: 'Qtde', class: 'text-center' },
          { key: 'PromotoresQualitativo', label: 'Qualitativo', class: 'text-center', formatter: (value) => `${value}%` },
          { key: 'DetratoresQtd', label: 'Qtde', class: 'text-center' },
          { key: 'DetratoresQualitativo', label: 'Qualitativo', class: 'text-center', formatter: (value) => `${value}%` },
          { key: 'NeutrosQtd', label: 'Qtde', class: 'text-center' },
          { key: 'NeutrosQualitativo', label: 'Qualitativo', class: 'text-center', formatter: (value) => `${value}%` },
          { key: 'NPS', label: 'NPS Média', class: 'text-center', formatter: (value) => `${value}` },
        ],
      }
    },
    created() {
        this.fillData();
    },
    watch: {
        totalizer: {
        handler(newVal) {
            this.fillData(); // Call fillData when totalizer prop changes
        },
        deep: true
        }
    },
    methods: {
        fillData() {
            const defaultTotalizer = {
                surveys_sent: 0,
                surveys_answered: 0,
                percentage_of_answered_surveys: "0.00%",
                nps_promoters: 0,
                percentage_of_nps_promoters: "0.00%",
                nps_detractors: 0,
                percentage_of_nps_detractors: "0.00%",
                nps_neutrals: 0,
                percentage_of_nps_neutrals: "0.00%",
                nps_average: "0.00%"
            };

            const data = this.totalizer || defaultTotalizer;

            this.items = [{
                QTDEnviadas: data.surveys_sent,
                QTDRespondidas: data.surveys_answered,
                percRespostas: parseFloat(data.percentage_of_answered_surveys),
                PromotoresQtd: data.nps_promoters,
                PromotoresQualitativo: parseFloat(data.percentage_of_nps_promoters),
                DetratoresQtd: data.nps_detractors,
                DetratoresQualitativo: parseFloat(data.percentage_of_nps_detractors),
                NeutrosQtd: data.nps_neutrals,
                NeutrosQualitativo: parseFloat(data.percentage_of_nps_neutrals),
                NPS: parseFloat(data.nps_average),
            }];
        }
    }
  };
  </script>
  
  <style scoped>
  .totalizer-container {
    /* Styles for the container */
  }
  
  .totalizer-item {
    /* Styles for each totalizer item */
  }
  
  .totalizer-title {
    /* Styles for the title of each item */
  }
  
  .totalizer-value {
    /* Styles for the value of each item */
  }
  
  .text-center th {
    text-align: center;
  }
  </style>
  